import { render, staticRenderFns } from "./inputcheck.vue?vue&type=template&id=56328e90&"
import script from "./inputcheck.js?vue&type=script&lang=js&"
export * from "./inputcheck.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projects\\EmployeePortalUpdated\\Frontend\\EmployeePortal.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56328e90')) {
      api.createRecord('56328e90', component.options)
    } else {
      api.reload('56328e90', component.options)
    }
    module.hot.accept("./inputcheck.vue?vue&type=template&id=56328e90&", function () {
      api.rerender('56328e90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/shared/common/inputs/inputcheck.vue"
export default component.exports